<template>
    <div>
        <el-upload
                :disabled="isDisabled"
                :action="actionUrl"
                list-type="picture-card"
                accept=".jpg,.jpeg,.png"
                :file-list="fileLists"
                :http-request="upload"
                :auto-upload="false"
                :on-change="handleChange"
                :class="{hide:hideUploadEdit}">
            <i slot="default" class="el-icon-plus" ></i>
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail"  :src="file.url" style="height: 170px;width: 170px">
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file)" v-if="!isDisabled">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

        <!-- 剪裁组件弹窗 -->
        <el-dialog
                :visible.sync="cropperModel"
                width="800px"
                :before-close="beforeClose"
                :modal="false"
        >
            <CropperImage
                    :img-file="file"
                    ref="vueCropper"
                    :fixedNumber="fixedNumber"
                    :autoCropWidth="width"
                    :autoCropHeight="height"
                    @upload="upload"
            >
            </CropperImage>
        </el-dialog>
    </div>
</template>

<script>
    import ty from "../../utils/ty";
    import CropperImage from "./CropperImage";
    import axios from "axios";
    export default {
        name: "VploadImg",
        components:{
            CropperImage
        },
        props:{
            width: {
                // 单图剪裁框宽度
                type: Number,
                default: 178,
            },

            height: {
                // 单图剪裁框高度
                type: Number,
                default: 178,
            },

            fixedNumber: {
                // 剪裁框比例设置
                default: function () {
                    return [1, 1.3];
                },
            },
            limit:{
                type:Number,
                default:12,
            },
            action:{
                type:String,
                default:'/common/uploadFileUrl'
            },
            type:{
                type:Number,
                default:0
            },
            isDisabled:{
              type:Boolean,
              default:false
            },
            fileList:{
                type:Array,
                default: ()=>{
                    return []
                }
            }
        },
        data(){
            return{
                dialogImageUrl: '',
                dialogVisible: false,
                cropperModel: false, // 剪裁组件弹窗开关
                actionUrl: this.action+'?fileType=0&type='+this.type,
                hideUploadEdit:this.fileList.length>=this.limit,
                fileLists:this.fileList,
                editForm:{
                    url:'',
                    uid:null
                },
                editView:false,
                file: '', // 当前被选择的图片文件
            }
        },
        updated () {
            if (this.$refs.vueCropper) {
                this.$refs.vueCropper.Update()
            }
        },
        watch:{
            fileList(n,o){ //n为新值,o为旧值;
                this.fileLists = n;
                this.hideUploadEdit=this.fileList.length>=this.limit
            }
        },
        methods:{

            beforeClose (done) {
                this.fileLists.pop()
                this.cropperModel = false
            },
            // 移除图片
            handleRemove(file) {
                for(let i in this.fileLists){
                    if(this.fileLists[i].uid == file.uid){
                        this.fileLists.splice(i,1)
                    }
                }
                this.hideUploadEdit = this.fileLists.length>=1;
                this.submitFile(file.url,0)
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },

            //上传图片
            upload(data) {
                console.log("dTE:",data)
                let formData = new FormData();
                formData.append("file", data, "DX.png");

                console.log(data);
                this.$axios.post(this.actionUrl, formData).then((res) => {
                    let obj = {
                        status: "success",
                        url: res.msg
                    }
                    this.cropperModel = false
                    this.fileLists.push(obj)
                    this.submitFile(res.msg,1)
                });
            },

            handleChange(file,fileList){
                    // 点击弹出剪裁框
                this.cropperModel = true
                this.file = file
                //this.fixedNumber=[file.width,file.height]
                // this.imageUrl = file.url
                this.hideUploadEdit = fileList.length>=1;
            },
            handleSuccess(response,file,fileList){
                let obj = {
                    name: file.name,
                    status: "success",
                    uid: file.uid,
                    url: response.msg
                }
                this.fileLists.push(obj)
                this.submitFile(response.msg,1)
            },
            // 将图片文件传回给父组件
            submitFile(filePath,add){
                //add:0是删除。1是新增
                this.$emit('submitImg',filePath,add)
            },
        }
    }
</script>

<style scoped>
    .el-icon-plus{
        font-size: 30px!important;
    }

    .el-icon-zoom-in{
        font-size: 18px !important;
    }
    .el-icon-delete{
        font-size: 18px !important;
        color:rgb(243, 143, 130);
    }
    .el-input>>> .el-textarea__inner{
        font-size:18px!important;
    }
    .hide  >>> .el-upload--picture-card{
        display: none;
    }
</style>
